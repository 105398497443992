import React, { useContext } from "react";
import Button from "../../button/Button";
import { ContextData } from "../../../context/Context";
import LoginAccountModal from "../../modals/LoginAccountModal";
import { Link } from "react-router-dom";
import accounticon from "../../../assets/accounticon.svg";
import cart from "../../../assets/cart.webp";

function Account() {
  const { setOpenloginmodal, loggedIn, userid } = useContext(ContextData);

  const handleopen = () => {
    setOpenloginmodal(true);
  };
  return (
    <div className="flex flex-row items-center gap-4">
      {loggedIn && userid ? (
        <>
          <Link to="/user" className="flex flex-row items-center gap-4">
            <img
              src={accounticon}
              alt="accounticon"
              className="cursor-pointer size-12 border-2 border-[#FF8213] p-2 rounded-full"
            />
          </Link>
          <Link to="/cart">
            <img src={cart} alt="cart" className="size-8" />
          </Link>
        </>
      ) : (
        <div className="flex flex-row items-center gap-4">
          <div className="hover:shadow-customloginsign rounded-[10px]">
            <Button
              label="Login / SignUp"
              style={{
                backgroundColor: "transparent",
                color: "black",
                borderColor: "#000000",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
              onClick={handleopen}
            />
          </div>
          <Link to="/cart">
            <img src={cart} alt="cart" className="size-8" />
          </Link>
        </div>
      )}
      <LoginAccountModal />
    </div>
  );
}

export default Account;
